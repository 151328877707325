import React from 'react'
import { PageProps } from 'gatsby'
import ServiceContent from '../../components/ServiceContent'
import Button from '../../components/Button'
import { magasinLink } from '../../data/menu'
import AdditionalTiles from '../../components/AdditionalTiles'
import reconditionnesTiles from '../../data/reconditionnesTiles'
import ServiceBanner from '../../components/ServiceBanner'
import { servicesBreadcrumbElement } from '.'
import { LayoutProps } from '../../components/Layout.context'
import { Helmet } from 'react-helmet'

const SmartphonesReconditionnesPage: React.FC<PageProps> = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Achetez un reconditionné est le premier geste que vous pouvez faire pour la planète, si c'est un Save en plus, ca compte double !"
        />
      </Helmet>

      <ServiceBanner
        title={`Smartphones reconditionnés & smartphones d'occasion`}
        background="/images/services/3_smartphone_reconditionne/page-sma-reco.png"
      />

      <ServiceContent>
        <ServiceContent.Paragraph>
          Le dernier modèle de votre marque préférée coûte trop cher ? Vous y
          êtes allé vraiment trop fort sur votre téléphone et sa réparation
          coûte vraiment trop cher cette fois-ci ? Optez pour un reconditionné !
          Vous ferez un petit geste pour la planète et un grand geste pour votre
          porte-monnaie.
        </ServiceContent.Paragraph>
        <Button.ExternalLink href={magasinLink}>
          Où en trouver ?
        </Button.ExternalLink>
        <ServiceContent.Title>
          Reconditionner, comment vous faites ca ?
        </ServiceContent.Title>
        <ServiceContent.Paragraph>
          Les smartphones que nous vendons sont scannés pour connnaitre leur
          état intérieur et les composants minutieusement vérifiés pour garantir
          que votre nouveau compagnon de route vous accompagne longtemps.
        </ServiceContent.Paragraph>
        <ServiceContent.Paragraph>
          Lorsque la moindre défaillance est détectée sur un téléphone, nous
          remplaçons le composant déféctueux par une pièce détachées d'origine
          lorsque nous pouvons.
        </ServiceContent.Paragraph>
        <ServiceContent.Paragraph>
          Une fois le téléphone comme neuf, nous le remplaçons dans une boîte
          avec tous les accessoires dont vous aurez besoin pour son bon
          fonctionnement, et… il vous attend ;)
        </ServiceContent.Paragraph>
        <ServiceContent.Title>
          Qu'est ce que ca m'apporte par rapport à mon smartphone neuf ?
        </ServiceContent.Title>
        <ServiceContent.Paragraph>
          Un smartphone reconditionné est toujours moins cher qu'un produit
          neuf. Le produit que vous aurez entre vos mains sera quasiment
          équivalent à un neuf, puisque les pièces détachées défaillantes auront
          été remplacées, tout cela pour un coût environ 30% moins cher.
        </ServiceContent.Paragraph>
        <ServiceContent.Paragraph>
          Alors est-ce que l'heure de se faire plaisir n'est pas arrivée ? Et
          d'enfin succomber aux attraits du téléphone qui vous fait rêver depuis
          tout ce temps, mais que raisonnablement vous ne pouviez pas vous
          offrir ?
        </ServiceContent.Paragraph>
        <ServiceContent.Paragraph>
          Profitez dès aujourd'hui des qualités d'un smartphone flambant neuf
          reconditionné au prix d'un smartphone d'occasion, bienvenue dans le
          futur, bienvenue chez Save.
        </ServiceContent.Paragraph>
        <AdditionalTiles items={reconditionnesTiles} />
      </ServiceContent>
    </>
  )
}

const layoutProps: LayoutProps = {
  title: 'Oui, le leader de la réparation européen reconditionne également',
  breadcrumb: [
    servicesBreadcrumbElement,
    { label: 'Smartphones reconditionnés' },
  ],
  showHighlights: true,
}

export default Object.assign(SmartphonesReconditionnesPage, {
  layoutProps,
})
