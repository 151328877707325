import { AdditionalTiles } from '../models/AdditionalTile'

const reconditionnesTiles: AdditionalTiles = [
  { image: '/images/services/3_smartphone_reconditionne/reconditionne-01.jpg' },
  { image: '/images/services/3_smartphone_reconditionne/reconditionne-02.jpg' },
  { image: '/images/services/3_smartphone_reconditionne/reconditionne-03.jpg' },
  { image: '/images/services/3_smartphone_reconditionne/reconditionne-04.jpg' },
]

export default reconditionnesTiles
